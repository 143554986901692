import { Box, Flex, FlexProps } from '@chakra-ui/react';
import Link from 'next/link';
import { FC } from 'react';
import { PersonTeaserFragmentFragment } from '../../generated/types';
import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';

type AuthorAndDateProps = FlexProps & {
  authors?: PersonTeaserFragmentFragment[];
  date?: string;
  renderDate?: boolean;
};

export const AuthorAndDate: React.FC<AuthorAndDateProps> = ({
  authors,
  date,
  renderDate = true,
  ...props
}) => {
  return (
    <>
      <Flex fontSize="sm" {...props}>
        {authors?.length > 0 && (
          <>
            <Box mr={1}>von</Box>
            {authors.map((author) => (
              <Box key={author.id}>
                <Link href={author.url} passHref>
                  <Box as="a" textDecor="underline">
                    {author.title}
                  </Box>
                </Link>
              </Box>
            ))}
          </>
        )}
        {renderDate && date && (
          <Box ml={1}>
            zuletzt aktualisiert {format(parseISO(date), 'dd.MM.yyyy')}
          </Box>
        )}
      </Flex>
    </>
  );
};
