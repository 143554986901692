import React, { useContext, useMemo } from 'react';
import { ParagraphHeaderFragmentFragment } from '../../../generated/types';
import useIsMobile from '../../../hooks/useIsMobile';
import { Box, Button, Flex, Img } from '@chakra-ui/react';
import Link from 'next/link';
import getAppPublicPath from '../../../utils/getAppPublicPath';
import { HTMLText } from '../../Common/HTMLText';
import PageHero from '../../Layout/PageHero';
import addFilterToSearchPageUrl from '../../../utils/addFilterToSearchPageUrl';
import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';
import differenceInDays from 'date-fns/differenceInDays';
import EntityContext from '../../../context/EntityContext';
import { AuthorAndDate } from '../../Common/AuthorAndDate';

export interface HeaderProps {
  data: ParagraphHeaderFragmentFragment;
}

const Header: React.FC<HeaderProps> = ({ data }) => {
  const isMobile = useIsMobile();
  const entityContext = useContext(EntityContext);

  const shouldShowDate = useMemo(() => {
    if (entityContext.entity.__typename === 'Page') {
      const parsed = parseISO(entityContext.entity.updatedAt);
      const diff = differenceInDays(new Date(), parsed);

      if (diff <= 365) {
        return true;
      }
    }

    return false;
  }, [entityContext]);

  const authors =
    entityContext.entity.__typename === 'Page'
      ? entityContext.entity.authors
      : [];

  return (
    <PageHero
      imageAlt={data.image?.imageAlt}
      imageTitle={data.image?.imageTitle}
      imageCopyright={data.image?.copyright}
      imageUrl={data.image?.hero.url}
    >
      <Box
        maxWidth={{
          base: 'none',
          md: '60%',
        }}
      >
        <HTMLText
          color="#fff"
          fontSize={{
            base: 'lg',
            md: 'xl',
          }}
          text={data.headline}
          __css={{
            h1: {
              fontWeight: 'bold',
              fontSize: {
                base: '3xl',
                md: '5xl',
              },
            },
            h2: {
              fontWeight: 'bold',
              fontSize: {
                base: '3xl',
                md: '5xl',
              },
            },
            h3: {
              fontSize: {
                base: 'lg',
                md: 'xl',
              },
            },
          }}
        />
        {data.linkUrl && data.linkText ? (
          <Box marginTop={4}>
            <Link
              href={addFilterToSearchPageUrl(
                data.linkUrl,
                data.countries,
                data.regions
              )}
            >
              <a>
                <Button as="span">{data.linkText}</Button>
              </a>
            </Link>
          </Box>
        ) : null}

        <AuthorAndDate
          authors={authors}
          date={
            entityContext.entity.__typename === 'Page' &&
            entityContext.entity.updatedAt
          }
          renderDate={shouldShowDate}
          mt={2}
        />
      </Box>
      {data.showAddon && !isMobile && (
        <Flex justifyContent="flex-end">
          <Img
            width="90%"
            htmlWidth={498}
            htmlHeight={450}
            src={`${getAppPublicPath()}/header-addon.png`}
          />
        </Flex>
      )}
    </PageHero>
  );
};

export default Header;
