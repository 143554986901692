import { useBreakpointValue } from '@chakra-ui/react';

export default function useHeaderHeight() {
  const height = useBreakpointValue({
    base: '60px',
    md: '80px',
  });

  return height || '80px';
}
