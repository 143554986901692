import {
  Conjunction,
  FilterInputGrouped,
  TermCountry,
  TermRegion,
} from '../generated/types';
import { filterInputGroupedToQueryString } from './parseFilterQueryString';
import queryString from 'query-string';

export default function addFilterToSearchPageUrl(
  url: string,
  countries?: Partial<TermCountry>[],
  regions?: Partial<TermRegion>[]
) {
  const groups: string[] = [];

  if (regions && regions.length) {
    const regionGroup: FilterInputGrouped = {
      conjunction: Conjunction.Or,
      conditions: regions.map((regionData) => ({
        operator: '=',
        field: 'region_tid',
        value: `${regionData.id}`,
      })),
    };

    groups.push(filterInputGroupedToQueryString(regionGroup));
  }

  if (countries && countries.length) {
    const countryGroup: FilterInputGrouped = {
      conjunction: Conjunction.Or,
      conditions: countries.map((countryData) => ({
        operator: '=',
        field: 'country_tid',
        value: `${countryData.id}`,
      })),
    };

    groups.push(filterInputGroupedToQueryString(countryGroup));
  }

  if (groups.length) {
    const stringified = queryString.stringify({
      groups,
    });

    if (stringified) {
      url += `?${stringified}`;
    }
  }

  return url;
}
