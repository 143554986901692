import { Box, Flex } from '@chakra-ui/react';
import CopyrightImage from '../Common/CopyrightImage';
import Container from './Container';
import React, { FC } from 'react';
import useIsMobile from '../../hooks/useIsMobile';
import Waves from '../Common/Waves';
import useHeaderHeight from '../../hooks/useHeaderHeight';

interface PageHeroProps {
  imageAlt?: string;
  imageTitle?: string;
  imageCopyright?: string;
  imageUrl?: string;
  darken?: boolean;
  copyrightPosition?: 'bottom' | 'top';
}

const PageHero: FC<PageHeroProps> = ({
  imageAlt,
  imageTitle,
  imageCopyright,
  imageUrl,
  darken,
  copyrightPosition = 'bottom',
  children,
}) => {
  const isMobile = useIsMobile();
  const height = useHeaderHeight();

  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      marginTop={{
        base: '-60px',
        md: '-80px',
      }}
      pos="relative"
      height={{
        base: '400px',
        md: '700px',
      }}
      overflow="hidden"
    >
      <Box>
        <CopyrightImage
          copyright={imageCopyright || ''}
          alt={imageAlt || ''}
          title={imageTitle || ''}
          pos="absolute"
          top="0"
          right="0"
          bottom="0"
          left="0"
          bg="#000"
          maxW="none"
          width="100%"
          height={{
            base: '400px',
            md: '700px',
          }}
          objectFit="cover"
          zIndex={1}
          src={imageUrl}
          copyrightPosition={copyrightPosition}
          copyrightOffset={height}
        />
        {darken && (
          <Box
            pos="absolute"
            left={0}
            right={0}
            bottom={0}
            top={0}
            bg="rgba(0,0,0, .5)"
            zIndex={2}
          />
        )}
      </Box>
      <Box
        pos="relative"
        zIndex={6}
        color="#fff"
        marginTop={height}
        width="100%"
      >
        <Container>
          <Flex
            pb={{
              base: '120px',
              md: '160px',
            }}
            alignItems="center"
            justifyContent="space-between"
          >
            {children}
          </Flex>
        </Container>
      </Box>
      <Waves />
    </Flex>
  );
};

export default PageHero;
